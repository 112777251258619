import {createSlice} from '@reduxjs/toolkit';

export const initialState = {
    user: undefined,
    workspace: {},
    plan: {},
    properties: {
        is_demo: 0,
    },

};

const identitySlice = createSlice({
    name: 'identity',
    initialState,
    reducers: {
        setIdentity: (state, action) => {
            if (action.payload === null) {
                return {
                    ...initialState,
                    user: null,
                };
            }
            return action.payload;
        },
    },
    extraReducers: builder => {
    },
});

export const {setIdentity} = identitySlice.actions;

export default identitySlice.reducer;
