import {createBrowserRouter, createRoutesFromElements, Navigate, Route, RouterProvider} from "react-router-dom";
import authedSuspense from "./hocs/withAuthedAndSuspense";
import {ToastContainer} from 'react-toastify';
import authed from "./hocs/withAutorization";
import loadable from '@loadable/component';
import Layout from "./layouts/Layout";
import guest from "./hocs/withGuest";
import Head from "./components/base/Head";
import {Provider} from "react-redux";
import pMinDelay from 'p-min-delay';
import Error from "./pages/Error";
import store from "./store";

// Styles
import 'react-toastify/dist/ReactToastify.css';
import 'react-tooltip/dist/react-tooltip.css'

// Translation
import './lang/i18n';

// Pages
import LoginPage from "./pages/LoginPage";
import LogoutPage from "./pages/LogoutPage";
import AuthProvider from "./hocs/AuthProvider";

// Lazy Pages
const lazyDelay = 0;
const MediaCreatePage = loadable(() => pMinDelay(import('./pages/MediaCreatePage'), lazyDelay));
const DashboardPage = loadable(() => pMinDelay(import('./pages/DashboardPage'), lazyDelay));
const SettingsPage = loadable(() => pMinDelay(import('./pages/SettingsPage'), lazyDelay));
const MediaPage = loadable(() => pMinDelay(import('./pages/MediaPage'), lazyDelay));
const PublicMediaPage = loadable(() => pMinDelay(import('./pages/PublicMediaPage'), lazyDelay));
const OnboardingPlanPage = loadable(() => pMinDelay(import('./pages/OnboardingPlanPage'), lazyDelay));
const OnboardingSubscriptionSuccess = loadable(() => pMinDelay(import('./pages/OnboardingSubscriptionSuccess'), lazyDelay));
const MediaEditPage = loadable(() => pMinDelay(import('./pages/MediaEditPage'), lazyDelay));
const WorkspaceEditPage = loadable(() => pMinDelay(import('./pages/WorkspaceEditPage'), lazyDelay));
const ToSPage = loadable(() => pMinDelay(import('./pages/ToSPage'), lazyDelay));

const router = function () {
    return createBrowserRouter(
        createRoutesFromElements(
            <Route path="/" errorElement={<Error noLayout/>}>
                <Route element={<Layout name="empty"/>} errorElement={<Error/>}>
                    <Route path="auth/signin" element={guest(LoginPage)} />
                    <Route path="auth/signout" element={authed(LogoutPage)}/>
                </Route>
                <Route element={<Layout name="base"/>} errorElement={<Error/>}>
                    <Route index element={<Navigate to="/dashboard"/>}/>
                    <Route path="/dashboard" element={authedSuspense(DashboardPage)}/>

                    <Route path="/transcriptions" element={authedSuspense(MediaPage)}/>
                    <Route path="/transcriptions/create" element={authedSuspense(MediaCreatePage)}/>
                    <Route path="/transcriptions/:id/edit" element={authedSuspense(MediaEditPage)}/>

                    <Route path="/public-transcriptions" element={authedSuspense(PublicMediaPage)}/>

                    <Route path="/onboarding/plan" element={authedSuspense(OnboardingPlanPage)}/>
                    <Route path="/onboarding/subscription/success"
                           element={authedSuspense(OnboardingSubscriptionSuccess)}/>

                    <Route path="/workspace/edit"
                           element={authedSuspense(WorkspaceEditPage)}/>

                    <Route path="/settings" element={authedSuspense(SettingsPage)}/>
                    <Route path="/settings/tos" element={authedSuspense(ToSPage)}/>
                </Route>
            </Route>
        )
    );
}

export default function App() {
    return (
        <Provider store={store}>
            <Head/>
            <AuthProvider router={router}>
                <RouterProvider router={router()}/>
            </AuthProvider>
            <ToastContainer/>
        </Provider>
    );
}
