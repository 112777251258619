import {Navigate, useLocation} from "react-router-dom";
import React from "react";
import {useSelector} from "react-redux";
import config from "../config";
import Notifications from "../classes/utilities/Notifications";

const redirectToOnboarding = config.redirectToOnboarding;

const tosExcludedPaths = [
    '/settings/tos',
    '/auth/signout',
    '/auth/signin'
];

const workspaceCountryExcludedPaths = [
    '/workspace/edit',
    '/settings/tos',
    '/auth/signout',
    '/auth/signin'
];

const withAuthorization = Component => {

    const WithAuthorization = function () {
        const {user, workspace, properties} = useSelector((state) => state.identity);
        const {pathname} = useLocation();

        if (user === null) {
            return <Navigate to="/auth/signin" replace={true}/>
        }

        if (user.terms_accepted !== true && tosExcludedPaths.includes(pathname) === false) {
            return <Navigate to="/settings/tos" replace={true}/>
        }

        if (workspace.country === null && workspaceCountryExcludedPaths.includes(pathname) === false) {
            Notifications.warning('Por favor, agrega el país de tu espacio de trabajo.');
            return <Navigate to="/workspace/edit" replace={true}/>
        }

        if (workspace.has_subscription === false && properties.is_demo === false && redirectToOnboarding.includes(pathname)) {
            Notifications.component(<div><strong>Bienvenido a I ♡ Transcriptions!</strong><br/>Para continuar, debes suscribirte.</div>)
            return <Navigate to="/onboarding/plan" replace={true}/>
        }

        return <Component/>;
    }

    return <WithAuthorization/>
}

export default withAuthorization;
