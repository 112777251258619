const profile = process.env.APP_PROFILE;

const apiEndpoints = {
    local: "http://localhost:8888/api",
    production: "https://api.ilovetranscriptions.com/api"
}

const config = {
    appName: 'I ♡ Transcriptions',
    environment: process.env.NODE_ENV,
    profile: profile,
    apiEndpoint: apiEndpoints[profile] || apiEndpoints.local,
    pusher: {
        appKey: "c6044178a8c7e25db828",
        cluster: "us2"
    },
    turnstileId: '0x4AAAAAAAY9u4uFTu2cpDB-',
    redirectToOnboarding: [
        '/transcriptions/create',
        '/dashboard'
    ],
    tos: 'https://howto.ilovetranscriptions.com/legal/terminos-y-condiciones',
}

export default config;
