import {ShoppingCartIcon} from "@heroicons/react/24/outline";
import {Link, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const hideOnPaths = [
    '/onboarding/plan',
    '/onboarding/subscription/success',
    '/settings/tos',
    '/auth/signout',
]

export default function DemoAlert() {
    const {properties} = useSelector((state) => state.identity);
    const {pathname} = useLocation();
    const {t} = useTranslation();

    if (!properties.is_demo || hideOnPaths.includes(pathname)) {
        return null;
    }

    return (
        <div
            className="flex items-center justify-between pt-4 transition-all duration-[.25s]">
            <div className="flex items-center w-full">
                <div className="alert flex overflow-hidden rounded-lg border border-warning w-full">
                    <div className="bg-warning font-bold p-3 text-white flex items-center">
                        {t('alerts.demo.title')}
                    </div>
                    <div className="p-2 flex-1 text-neutral-800">
                        <h4 className="text-md font-bold">{t('alerts.demo.heading')}</h4>
                        <p className="text-md">{t('alerts.demo.comment')}</p>
                    </div>
                    <div className="flex items-center pr-2">
                        <Link to="/onboarding/plan"
                              className="btn bg-gradient-to-r from-amber-500 to-amber-600 hover:underline font-medium text-white">
                            <ShoppingCartIcon className="h-6 w-6 mr-2"/> {t('global.subscribe')}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
