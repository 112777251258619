export default {
  // Global
  "translation": {
    // Global
    "global.terms": "利用規約",
    "global.loaderText": "読み込み中、しばらくお待ちください。",
    "global.comingSoon": "近日公開",
    "global.subscribe": "購読する",
    "global.select": "選択",
    "global.save": "保存",
    "global.wait": "しばらくお待ちください",

    // languages
    "languages.en": "英語",
    "languages.es": "スペイン語",
    "languages.ja": "日本語",

    // Countries
    "country.mexico": "メキシコ",
    "country.other": "その他 - メキシコ外",

    // Navigation
    "navigation.balance": "{{ credits }}クレジット",
    "navigation.noSubscription": "サブスクリプションなし",
    "navigation.userMenu.configuration": "設定",
    "navigation.userMenu.signOut": "ログアウト",

    // SideBar
    "sidebar.dashboard": "ダッシュボード",
    "sidebar.transcriptions": "書き起こし",
    "sidebar.publicTranscriptions": "公式書き起こし",
    "sidebar.help": "ヘルプ",
    "sidebar.settings": "設定",

    // Alerts
    "alerts.demo.title": "DEMO",
    "alerts.demo.heading": "I ♡ Transcriptionsの無料トライアルを使用しています。",
    "alerts.demo.comment": "機能が制限されている場合がありますので、プランに登録することをお勧めします。",
    "alerts.subscription.title": "サブスクリプションがありません",
    "alerts.subscription.comment": "サービスを利用するにはアクティブなサブスクリプションが必要です。それがないと機能が制限されます。サブスクリプションを購入するには、購読するボタンをクリックしてください。",

    // Login
    "login.pageTitle": "ログイン",
    "login.title": "ようこそ",
    "login.subtitle": "続行するにはログインしてください",
    "login.button": "Googleでログイン",
    "login.comment": "セキュリティ上の理由から、プラットフォームへのアクセスはGoogle認証のみを受け付けています。",

    // Logout
    "logout.title": "ログアウト中",
    "logout.success": "ログアウトしました",

    // Dashboard
    "dashboard.title": "ダッシュボード",
    "dashboard.welcome": "{{name}}さん、ようこそ！",
    "dashboard.addCredits": "クレジットを追加する",
    "dashboard.credits": "クレジット",
    "dashboard.usedTime": "総使用時間",

    // Transcriptions
    "transcriptions.title": "書き起こし",
    "transcriptions.create": "新規作成",
    "transcriptions.create.dragFile": "ここにファイルをドラッグしてください",
    "transcriptions.create.maximumFileSize": "最大ファイルサイズ",
    "transcriptions.create.continue": "次へ",
    "transcriptions.create.back": "戻る",
    "transcriptions.create.name": "書き起こし名",
    "transcriptions.create.name.placeholder": "書き起こしの名前",
    "transcriptions.create.language": "言語",
    "transcriptions.create.engine": "音声認識エンジン",
    "transcriptions.create.automatic": "アップロード完了後に自動的に音声書き起こしする",
    "transcriptions.create.automatic.description": "クレジットが十分にある場合、チェックを入れるとデータが自動的に音声文字起こしされます。",
    "transcriptions.create.automatic.notice": "データは安全に24時間保持されます。この期間内にメディアコンテンツを音声文字起こししない場合、システムが自動的に削除します。",
    "transcriptions.create.sttEngine.empty": "利用可能なオプションを確認するために言語を選択してください。",
    "transcriptions.create.sttEngine.consumesCredits": "クレジット消費",
    "transcriptions.create.sttEngine.unlimited": "無制限",
    "transcriptions.create.largeFile": "<0>ファイルサイズが{{size}}です。大きめのファイルです。</0><br />アップロードは可能ですが、アップロードを効率化するために事前に圧縮することをお勧めします。<br/>詳細については<1>ヘルプページ</1>を参照してください。",
    "transcriptions.create.uploading": "データをアップロード中、しばらくお待ちください",
    "transcriptions.create.uploading.description": "このページから移動しないでください。そうしないとアップロードがキャンセルされます。",
    "transcriptions.create.progress": "{{progress}}% 完了",
    "transcriptions.create.toast.uploaded": "{{name}}がアップロードされました。",
    "transcriptions.table.empty": "書き起こしがありません。",
    "transcriptions.table.add": "<p>最初の音声文字起こしを<br/>作成するにはクリックしてください。</p>",
    "transcriptions.table.status": "ステータス",
    "transcriptions.table.name": "名前",
    "transcriptions.table.date": "日付",
    "transcriptions.table.delete": "削除",
    "transcriptions.table.transcribe": "書き起こし",
    "transcriptions.table.transcribeSuccess": "{{name}}が音声文字起こしキューに送信されました。",
    "transcriptions.table.deleteSuccess": "{{name}}の音声文字起こしが削除されました。",
    "transcriptions.table.deleteConfirm": "この音声文字起こしを削除しますか？一度削除するとデータを復元することはできません。",
    "transcriptions.edit.forbidden": "この音声文字起こしを編集することはできません。",
    "transcriptions.edit.details": "詳細",
    "transcriptions.edit.supportId": "サポートID",
    "transcriptions.edit.saveSuccess": "書き起こしが保存されました",
    "transcriptions.edit.saveFailed": "保存できませんでした。後でもう一度お試しください。",
    "transcriptions.edit.hasChanges": "未保存の変更があります。ダウンロードする前に保存してください。",
    "transcriptions.edit.title": "書き起こしを編集する",
    "transcriptions.edit.readOnly": "読み取り専用",
    "transcriptions.public.title": "公式書き起こし",

    // Settings
    "settings.title": "設定",
    "settings.plan.noSubscription.title": "I ♡ Transcriptionsに登録する",
    "settings.plan.noSubscription.heading": "アクティブなサブスクリプションがありません",
    "settings.plan.noSubscription.comment": "以下のボタンを使用してサブスクリプションを購入してください",
    "settings.plan.subscription.title": "I ♡ Transcriptionsに登録済み",
    "settings.plan.subscription.heading": "現在のサブスクリプションはアクティブです",
    "settings.plan.subscription.comment": "サブスクリプションを変更する場合は、管理するボタンをクリックしてください",
    "settings.plan.subscription.manage": "サブスクリプションを管理する",

    // ToS
    "tos.title": "利用規約",
    "tos.heading": "I ♡ Transcriptionsの利用には以下の利用規約に同意する必要があります:",
    "tos.url": "https://dl.komoni.mx/ilovetranscriptions/tos.html",
    "tos.link": "<0>こちら</0>をクリックして新しいウィンドウで表示する",
    "tos.comment": "クリックすることで、上記の利用規約に同意することを確認します",
    "tos.accept": "同意する",
    "tos.decline": "同意しない",
    "tos.verify": "リクエストを確認中...",
    "tos.sending": "データを送信中",

    // Workspace Edit
    "workspace.edit.title": "ワークスペース編集",
    "workspace.edit.name": "ワークスペース名",
    "workspace.edit.country": "請求国",
    "workspace.edit.countryHint": "支払い方法に関連する国を選択してください。実際の居住国と異なる場合でも構いません。",
    "workspace.edit.saving": "ワークスペースを保存中",

    // Engines
    "engines.vossy.name": "Vossy",
    "engines.vossy.fast": "高速",
    "engines.vossy.fast.description": "低精度な高速な音声文字起こし",
    "engines.vossy.general": "一般",
    "engines.vossy.general.description": "中程度の精度の低速な音声文字起こし",
    "engines.whisper.name": "Open AI Whisper",
    "engines.whisper.description": "高速で高精度な音声文字起こし",
    "engines.azure.name": "Microsoft Azure",
    "engines.azure.description": "高精度な低速な音声文字起こし",

    // Onboarding
    "onboarding.title": "プラン",
    "onboarding.loading": "プランを読み込んでいます",
    "onboarding.included": "あなたのプランに含まれるもの:",
    "onboarding.choose": "プランを選択してください",
    "onboarding.subscription.anual": "年間サブスクリプション",
    "onboarding.subscription.monthly": "月間サブスクリプション",
    "onboarding.subscription.twoMonthsDiscount": "2ヶ月無料！",
    "onboarding.subscription.notes": "I ♡ Transcriptionsはクレジットカード情報を保存しません。支払いは安全な決済ゲートウェイであるStripeを通じて行われます。「購読する」をクリックすることで、再度利用規約に同意したことになります。",
    "onboarding.subscription.success": "購読ありがとうございます！",
    "onboarding.subscription.provision": "サブスクリプションを有効にするまでお待ちください。",
    "onboarding.subscriptions.feature1": "<0>Vossy</0>エンジンで<strong>無制限</strong>の音声文字起こし",
    "onboarding.subscriptions.feature2": "さまざまなフォーマットでのダウンロード",
    "onboarding.subscriptions.feature3": "いつでもキャンセル可能",

    // Media
    "media.status.waiting": "待機中",
    "media.status.waiting.description": "音声文字起こしが作成されましたが、ファイルがアップロードされていません。",
    "media.status.uploading": "アップロード中",
    "media.status.uploading.description": "ファイルのアップロード中です。",
    "media.status.validating": "検証中",
    "media.status.validating.description": "アップロードされたファイルを検証しています。",
    "media.status.pending": "保留中",
    "media.status.pending.description": "ファイルがアップロードされ、処理待ちです。アカウントに十分な分数がない場合、分数があるときに音声文字起こしされます。",
    "media.status.queued": "処理待ち",
    "media.status.queued.description": "音声文字起こしキューで処理待ちです。",
    "media.status.processing": "処理中",
    "media.status.processing.description": "音声文字起こしの処理中です。ファイルの長さに応じて時間がかかることがあります。",
    "media.status.failed": "失敗",
    "media.status.failed.description": "失敗しました",
    "media.status.completed": "完了",
    "media.status.completed.description": "完了しました",
    "media.status.expired": "期限切れ",
    "media.status.expired.description": "データは24時間後に自動的に削除され、処理できません。",

    // Vossy Status
    "vossyStatus.queue": "キューに入れられました: {{ queued }}",
    "vossyStatus.online": "I ♡ T Vossyの全体的な状態。現在{{queued}}件の音声文字起こしがキューに入っています。",
    "vossyStatus.offline": "I ♡ T Vossyはオフラインです。音声文字起こしが処理されない可能性があります。",

    // Table view
    "tableview.pager.showing": "{{from}}〜{{to}}　（{{count}}件）",
  }
}
