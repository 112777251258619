export default {
    // Global
    "translation": {
        // Global
        "global.terms": "Terms and Conditions",
        "global.loaderText": "Loading, please wait.",
        "global.comingSoon": "Coming Soon",
        "global.subscribe": "Subscribe",
        "global.select": "Select",
        "global.save": "Save",
        "global.wait": "Please wait",

        // Languages
        "languages.en": "English",
        "languages.es": "Spanish",
        "languages.ja": "Japanese",

        // Countries
        "country.mexico": "Mexico",
        "country.other": "Other - Outside Mexico",

        // Navigation
        "navigation.balance": "{{ credits }} credits",
        "navigation.noSubscription": "No subscription",
        "navigation.userMenu.configuration": "Configuration",
        "navigation.userMenu.signOut": "Sign out",

        // SideBar
        "sidebar.dashboard": "Dashboard",
        "sidebar.transcriptions": "Transcriptions",
        "sidebar.publicTranscriptions": "Public Transcriptions",
        "sidebar.help": "Help",
        "sidebar.settings": "Settings",

        // Alerts
        "alerts.demo.title": "DEMO",
        "alerts.demo.heading": "You are using the trial version of I ♡ Transcriptions.",
        "alerts.demo.comment": "Functionality may be limited, we recommend subscribing to a plan.",
        "alerts.subscription.title": "You do not have an active subscription",
        "alerts.subscription.comment": "You need an active subscription to use the service; without it, functionality will be limited. Click the subscribe button to purchase a subscription.",

        // Login
        "login.pageTitle": "Login",
        "login.title": "Welcome",
        "login.subtitle": "Please log in to continue",
        "login.button": "Login with Google",
        "login.comment": "For security, we only accept Google authentication to access the platform.",

        // Logout
        "logout.title": "Logging out",
        "logout.success": "You have successfully logged out",

        // Dashboard
        "dashboard.title": "Dashboard",
        "dashboard.welcome": "Welcome {{name}}!",
        "dashboard.addCredits": "Add credits",
        "dashboard.credits": "Credits",
        "dashboard.usedTime": "Total time used",

        // Transcriptions
        "transcriptions.title": "Transcriptions",
        "transcriptions.create": "New transcription",
        "transcriptions.crate.dragFile": "Drag a file here",
        "transcriptions.create.maximumFileSize": "Maximum file size",
        "transcriptions.create.continue": "Continue",
        "transcriptions.create.back": "Back",
        "transcriptions.create.name": "Name",
        "transcriptions.create.name.placeholder": "Transcription name",
        "transcriptions.create.language": "Language",
        "transcriptions.create.engine": "Transcription engine",
        "transcriptions.create.automatic": "Automatically transcribe after upload completes.",
        "transcriptions.create.automatic.description": "If checked, data will be transcribed automatically if you have enough credits in your workspace.",
        "transcriptions.create.automatic.notice": "Data will be securely retained for a period of 24 hours. If you do not transcribe your media content within this time period, the system will automatically delete it.",
        "transcriptions.create.sttEngine.empty": "Select a language to see available options.",
        "transcriptions.create.sttEngine.consumesCredits": "Consumes credits",
        "transcriptions.create.sttEngine.unlimited": "Unlimited",
        "transcriptions.create.largeFile": "<0>Your file weighs {{size}}, it's on the larger side.</0><br/>You can still upload it, but, to enhance uploading, you may want to compress it first.<br/>Refer to <1>how to</1> page for assistance.",
        "transcriptions.create.uploading": "Uploading data, please wait",
        "transcriptions.create.uploading.description": "Do not leave this page or the upload will be canceled.",
        "transcriptions.create.progress": "{{progress}}% completed",
        "transcriptions.create.toast.uploaded": "{{name}} uploaded.",
        "transcriptions.table.empty": "No transcriptions!",
        "transcriptions.table.add": "<p>Click to create <br/>your first transcription</p>",
        "transcriptions.table.status": "Status",
        "transcriptions.table.name": "Name",
        "transcriptions.table.date": "Date",
        "transcriptions.table.delete": "Delete",
        "transcriptions.table.transcribe": "Transcribe",
        "transcriptions.table.transcribeSuccess": "{{name}} sent to the transcription queue",
        "transcriptions.table.deleteSuccess": "Transcription {{name}} deleted",
        "transcriptions.table.deleteConfirm": "Delete this transcription? Once deleted, data cannot be recovered.",
        "transcriptions.edit.forbidden": "You cannot edit this transcription.",
        "transcriptions.edit.details": "Details",
        "transcriptions.edit.supportId": "Support ID",
        "transcriptions.edit.saveSuccess": "Transcription saved",
        "transcriptions.edit.saveFailed": "Could not save, please try again later.",
        "transcriptions.edit.hasChanges": "You have unsaved changes, please save before downloading",
        "transcriptions.edit.title": "Edit transcription",
        "transcriptions.edit.readOnly": "Read only",
        "transcriptions.public.title": "Public transcriptions",

        // Settings
        "settings.title": "Settings",
        "settings.plan.noSubscription.title": "Subscribe to I ♡ Transcriptions",
        "settings.plan.noSubscription.heading": "You do not have an active subscription",
        "settings.plan.noSubscription.comment": "Purchase a subscription using the button below",
        "settings.plan.subscription.title": "Subscribed to I ♡ Transcriptions",
        "settings.plan.subscription.heading": "Your subscription is active",
        "settings.plan.subscription.comment": "If you want to make changes to your subscription, click the Manage subscription button.",
        "settings.plan.subscription.manage": "Manage subscription",

        // ToS
        "tos.title": "Terms and Conditions",
        "tos.heading": "To use I ♡ Transcriptions you must accept the following terms and conditions:",
        "tos.url": "https://dl.komoni.mx/ilovetranscriptions/tos.html",
        "tos.link": "Click <0>here</0> to view in a new window.",
        "tos.comment": "By clicking, you confirm that you accept the <br/>terms and conditions shown above",
        "tos.accept": "I accept",
        "tos.decline": "I do not accept",
        "tos.verify": "Verifying your request...",
        "tos.sending": "Sending data",

        // Workspace
        "workspace.edit.title": "Edit workspace",
        "workspace.edit.name": "Workspace name",
        "workspace.edit.country": "Billing country",
        "workspace.edit.countryHint": "Select the country corresponding to your payment method, even if you reside in a different country.",
        "workspace.edit.saving": "Saving workspace",

        // Engines
        "engines.vossy.name": "Vossy",
        "engines.vossy.fast": "Fast",
        "engines.vossy.fast.description": "Fast low-accuracy transcription",
        "engines.vossy.general": "General",
        "engines.vossy.general.description": "Slow medium-accuracy transcription",
        "engines.whisper.name": "Open AI Whisper",
        "engines.whisper.description": "Fast transcription, high accuracy, maximum",
        "engines.azure.name": "Microsoft Azure",
        "engines.azure.description": "Slow high-accuracy transcription",

        // Onboarding
        "onboarding.title": "Plan",
        "onboarding.loading": "Loading plans",
        "onboarding.included": "Included in your plan:",
        "onboarding.choose": "Choose your plan",
        "onboarding.subscription.anual": "Annual subscription",
        "onboarding.subscription.monthly": "Monthly subscription",
        "onboarding.subscription.twoMonthsDiscount": "2 months free!",
        "onboarding.subscription.notes": "I ♡ Transcriptions does not store credit card information, payment is processed through Stripe, a secure payment gateway. By clicking \"Subscribe\", you confirm again that you have read and accepted our terms and conditions.",
        "onboarding.subscription.success": "Thank you for subscribing!",
        "onboarding.subscription.provision": "Please wait while your subscription is activated.",
        "onboarding.subscriptions.feature1": "<0>Unlimited</0> transcriptions with the <strong>Vossy</strong> engine",
        "onboarding.subscriptions.feature2": "Download in different formats",
        "onboarding.subscriptions.feature3": "Cancel anytime",

        // Media
        "media.status.waiting": "Waiting",
        "media.status.waiting.description": "The transcription has been created but the file has not been uploaded.",
        "media.status.uploading": "Uploading",
        "media.status.uploading.description": "The file is being uploaded.",
        "media.status.validating": "Validating",
        "media.status.validating.description": "The uploaded file is being validated.",
        "media.status.pending": "Pending",
        "media.status.pending.description": "The file has been uploaded and is waiting to be processed. If you do not have enough minutes in your account, it will be transcribed when you do.",
        "media.status.queued": "Queued",
        "media.status.queued.description": "The file is in the transcription queue waiting to be processed.",
        "media.status.processing": "Processing",
        "media.status.processing.description": "The file is being processed for transcription, this may take time depending on its duration.",
        "media.status.failed": "Failed",
        "media.status.failed.description": "",
        "media.status.completed": "Completed",
        "media.status.completed.description" : "",
        "media.status.expired" : "Expired",
        "media.status.expired.description" : "The data was automatically deleted after 24 hours and cannot be processed.",

        // Vossy Status
        "vossyStatus.queue": "In queue: {{ queued }}",
        "vossyStatus.online": "Global status of the I ♡ T Vossy transcription engine. There are {{queued}} transcriptions in the queue.",
        "vossyStatus.offline": "I ♡ T Vossy is offline, transcriptions might not be processing.",

        // Table view
        "tableview.pager.showing": "Showing {{from}} - {{to}} of {{count}}",
    }
}
