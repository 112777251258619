export default {
  // Global
  "translation": {
    // Global
    "global.terms": "Términos y condiciones",
    "global.loaderText": "Cargando, por favor espera.",
    "global.comingSoon": "Próximamente",
    "global.subscribe": "Suscribirse",
    "global.select": "Selecciona",
    "global.save": "Guardar",
    "global.wait": "Por favor, espera",

    // languages
    "languages.en": "Inglés",
    "languages.es": "Español",
    "languages.ja": "Japonés",

    // Countries
    "country.mexico": "México",
    "country.other": "Otro - Fuera de México",

    // Navigation
    "navigation.balance": "{{ credits }} créditos",
    "navigation.noSubscription": "Sin suscripción",
    "navigation.userMenu.configuration": "Configuración",
    "navigation.userMenu.signOut": "Cerrar sesión",

    // SideBar
    "sidebar.dashboard": "Dashboard",
    "sidebar.transcriptions": "Transcripciones",
    "sidebar.publicTranscriptions": "Transcripciones públicas",
    "sidebar.help": "Ayuda",
    "sidebar.settings": "Configuración",

    // Alerts
    "alerts.demo.title": "DEMO",
    "alerts.demo.heading": "Estás usando la versión de prueba de I ♡ Transcriptions.",
    "alerts.demo.comment": "La funcionalidad podría estar limitada, te recomendamos suscribirte un plan.",
    "alerts.subscription.title": "No tienes una suscripción activa",
    "alerts.subscription.comment": "Necesita una suscripción activa para utilizar el servicio; sin eso, la funcionalidad será limitada. Haga clic en el botón suscribirse para comprar una suscripción.",

    // Login
    "login.pageTitle": "Iniciar Sesión",
    "login.title": "Bienvenid@",
    "login.subtitle": "Por favor, inicia sesión para continuar",
    "login.button": "Accede con Google",
    "login.comment": "Por seguridad, solamente aceptamos la autenticación de Google para acceder a la plataforma.",

    // Logout
    "logout.title": "Cerrando sesión",
    "logout.success": "Has cerrado sesión exitosamente",

    // Dashboard
    "dashboard.title": "Dashboard",
    "dashboard.welcome": "Bienvenido {{name}}!",
    "dashboard.addCredits": "Agregar créditos",
    "dashboard.credits": "Créditos",
    "dashboard.usedTime": "Tiempo total usado",

    // Transcriptions
    "transcriptions.title": "Transcripciones",
    "transcriptions.create": "Nueva transcripción",
    "transcriptions.crate.dragFile": "Arrastra un archivo aquí",
    "transcriptions.create.maximumFileSize": "Tamano máximo de archivo",
    "transcriptions.create.continue": "Continuar",
    "transcriptions.create.back": "Atrás",
    "transcriptions.create.name": "Nombre",
    "transcriptions.create.name.placeholder": "Nombre de la transcripción",
    "transcriptions.create.language": "Idioma",
    "transcriptions.create.engine": "Motor de transcripción",
    "transcriptions.create.automatic": "Transcribir automáticamente después de que la carga se complete.",
    "transcriptions.create.automatic.description": "Si está marcada, los datos serán transcritos automáticamente si tiene suficientes créditos en su espacio de trabajo.",
    "transcriptions.create.automatic.notice": "Los datos serán retenidos de forma segura durante un período de 24 horas. En caso de que no haya transcrito su contenido multimedia dentro de este período de tiempo, el sistema se encargará de su eliminación automática.",
    "transcriptions.create.sttEngine.empty": "Selecciona un idioma para ver las opciones disponibles.",
    "transcriptions.create.sttEngine.consumesCredits": "Consume créditos",
    "transcriptions.create.sttEngine.unlimited": "Ilimitada",
    "transcriptions.create.largeFile": "<0>Your file weights {{size}}, it's on the larger side.</0><br />You can still upload it, but, to enhance uploading, you may want compress it first.<br/>Refer to <1>how to</1> page for assistance.",
    "transcriptions.create.uploading": "Cargando datos, por favor espera",
    "transcriptions.create.uploading.description": "No abandone esta página o la carga se cancelará.",
    "transcriptions.create.progress": "{{progress}}% completado",
    "transcriptions.create.toast.uploaded": "{{name}} subida.",
    "transcriptions.table.empty": "¡No hay transcripciones!",
    "transcriptions.table.add": "<p>Haz click para crear <br/>tu primera transcripción</p>",
    "transcriptions.table.status": "Estatus",
    "transcriptions.table.name": "Nombre",
    "transcriptions.table.date": "Fecha",
    "transcriptions.table.delete": "Borrar",
    "transcriptions.table.transcribe": "Transcribir",
    "transcriptions.table.transcribeSuccess": "{{name}} se envió a la cola de transcripción",
    "transcriptions.table.deleteSuccess": "Se borró la transcripción {{name}}",
    "transcriptions.table.deleteConfirm": "¿Borrar esta transcripción? Una vez borrada, los datos no se pueden recuperar.",
    "transcriptions.edit.forbidden": "No puedes editar esta transcripción.",
    "transcriptions.edit.details": "Detalles",
    "transcriptions.edit.supportId": "Id de soporte",
    "transcriptions.edit.saveSuccess": "Transcripción guardada",
    "transcriptions.edit.saveFailed": "No se pudo guardar, por favor intenta de nuevo más tarde.",
    "transcriptions.edit.hasChanges": "Tienes cambios sin guardar, por favor guarda antes de descargar",
    "transcriptions.edit.title": "Editar transcripción",
    "transcriptions.edit.readOnly": "Solo lectura",
    "transcriptions.public.title": "Transcripciones públicas",

    // Settings
    "settings.title": "Configuración",
    "settings.plan.noSubscription.title": "Suscríbete a I ♡ Transcriptions",
    "settings.plan.noSubscription.heading": "No tienes una suscripción activa",
    "settings.plan.noSubscription.comment": "Compra una suscripción usando el siguiente botón",
    "settings.plan.subscription.title": "Suscrito a I ♡ Transcriptions",
    "settings.plan.subscription.heading": "Tu suscripción está activa",
    "settings.plan.subscription.comment": "Si quieres hacer cambios en tu suscripción, has click en el botón Administrar suscripción.",
    "settings.plan.subscription.manage": "Administrar suscripción",

    // ToS
    "tos.title": "Términos y condiciones",
    "tos.heading": "Para utilizar I ♡ Transcriptions debes aceptar los siguientes términos y condiciones:",
    "tos.url": "https://dl.komoni.mx/ilovetranscriptions/tos.html",
    "tos.link": "Click <0>aquí</0> para ver en una ventana nueva.",
    "tos.comment": "Al hacer click, confirmas que aceptas los <br/>términos y condiciones que se muestran arriba",
    "tos.accept": "Acepto",
    "tos.decline": "No acepto",
    "tos.verify": "Verificando tu solicitud...",
    "tos.sending": "Enviando datos",

    // Workspace
    "workspace.edit.title": "Editar espacio de trabajo",
    "workspace.edit.name": "Nombre del espacio de trabajo",
    "workspace.edit.country": "País de facturación",
    "workspace.edit.countryHint": "Selecciona el país al que corresponde tu método de pago, aunque residas en un país diferente.",
    "workspace.edit.saving": "Guardando espacio de trabajo",

    // engines
    "engines.vossy.name": "Vossy",
    "engines.vossy.fast": "Rápida",
    "engines.vossy.fast.description": "Transcripción rápida de baja precisión",
    "engines.vossy.general": "General",
    "engines.vossy.general.description": "Transcripción lenta de precisión media",
    "engines.whisper.name": "Open AI Whisper",
    "engines.whisper.description": "Transcripción rápida, alta precisión, máximo",
    "engines.azure.name": "Microsoft Azure",
    "engines.azure.description": "Transcripción lenta de alta precisión",

    // Onboarding
    "onboarding.title": "Plan",
    "onboarding.loading": "Cargando planes",
    "onboarding.included": "Incluido en tu plan:",
    "onboarding.choose": "Escoge tu plan",
    "onboarding.subscription.anual": "Suscripción anual",
    "onboarding.subscription.monthly": "Suscripción mensual",
    "onboarding.subscription.twoMonthsDiscount": "¡2 meses gratis!",
    "onboarding.subscription.notes": "I ♡ Transcriptions no almacena información de tarjetas de crédito, el pago se realiza a través de Stripe, una pasarela segura de pagos. Al hacer click en \"Suscribirme\", confirmas nuevamente que has leído y aceptado nuestros términos y condiciones.",
    "onboarding.subscription.success": "¡Gracias por suscribirte!",
    "onboarding.subscription.provision": "Espere mientras se activa su suscripción.",
    "onboarding.subscriptions.feature1": "Transcripciones <0>ilimitadas</0> en el motor <strong>Vossy</strong>",
    "onboarding.subscriptions.feature2": "Descarga en diferentes formatos",
    "onboarding.subscriptions.feature3": "Cancela en cualquier momento",

    // Media
    "media.status.waiting" : "Esperando",
    "media.status.waiting.description" : "Se ha creado la transcripción pero no se ha subido el archivo.",
    "media.status.uploading" : "Cargando",
    "media.status.uploading.description" : "Se está subiendo el archivo.",
    "media.status.validating" : "Validando",
    "media.status.validating.description" : "Se está validando el archivo subido.",
    "media.status.pending" : "Pendiente",
    "media.status.pending.description" : "El archivo ha sido subido y está esperando ser procesado. Si no tienes minutos suficientes en tu cuenta, se transcribirá cuando los tengas.",
    "media.status.queued" : "En cola",
    "media.status.queued.description" : "El archivo esta en la cola de transcripción esperando ser procesado.",
    "media.status.processing" : "Procesando",
    "media.status.processing.description" : "El archivo esta siendo procesado para transcripción, esto puede tardar dependiendo de la duración del mismo.",
    "media.status.failed" : "Fallida",
    "media.status.failed.description" : "",
    "media.status.completed" : "Completada",
    "media.status.completed.description" : "",
    "media.status.expired" : "Expirada",
    "media.status.expired.description" : "Los datos fueron eliminados automáticamente después de 24 horas y no se pueden procesar.",

    // Vossy Status
    "vossyStatus.queue": "En cola: {{ queued }}",
    "vossyStatus.online": "Estado global del motor de transcripción I ♡ T Vossy. Hay {{queued}} transcripciones en cola.",
    "vossyStatus.offline": "I ♡ T Vossy esta fuera de línea, las transcripciones podrían no estarse procesando.",

    // Table view
    "tableview.pager.showing": "Mostrando {{from}} - {{to}} de {{count}}",
  }
}
