import title from "../../classes/utilities/title";
import {Helmet} from "react-helmet";

export default function Head(props) {
    return (
        <Helmet>
            <title>{title(props.title)}</title>
        </Helmet>
    );
}
