import ReactDom from "react-dom";
import {useTranslation} from "react-i18next";

export default function FullLoader(props) {
    const { t } = useTranslation();
    const active = props.active === true;
    const portal = (
        <div id="full-preloader">
            {active &&
                <div
                    className="flex w-screen h-screen justify-center items-center flex-col absolute inset-0 bg-slate-50 dark:bg-navy-900"
                    style={{zIndex: 1000}}>
                    <div
                        className="spinner h-10 w-10 animate-spin rounded-full border-[6px] border-info/30 border-r-info"/>
                    <h3 className="text-xl font-light mt-2">{t('global.loaderText')}</h3>
                </div>
            }
        </div>
    );

    return ReactDom.createPortal(portal, document.getElementById('preloader-root'));
}
