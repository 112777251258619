import {useEffect} from "react";
import Page from "../components/base/Page";
import InlineLoader from "../components/ui/InlineLoader";
import Auth from "../classes/api/Auth";
import Notifications from "../classes/utilities/Notifications";
import {useTranslation} from "react-i18next";

export default function LogoutPage() {
    const {t} = useTranslation();

    useEffect(() => {
        Auth.logout()
            .then(() => Notifications.success(t('logout.success')));
    }, []);

    return (
        <Page title={t('logout.title')} noTitle>
            <div className="py-5 text-center lg:py-6">
                <h2 className="text-lg uppercase">{t('logout.title')}</h2>
                <p className="text-sm">{t('global.wait')}</p>
                <InlineLoader/>
            </div>
        </Page>
    );
}
