import ReactDom from "react-dom/client";
import React from "react";
import App from "./App";
import config from "./config";
import TagManager from "react-gtm-module/dist/TagManager";
import GtmTracker from "./classes/utilities/gtm-tracker";

const container = document.getElementById("app-root");
const root = ReactDom.createRoot(container)

if (config.profile === 'production') {
    // TagManager.initialize({
    //    gtmId: 'GTM-P5NRMX5S'
    // });
    // GtmTracker.setDataLayer(window.dataLayer);
}

root.render(
    <App/>
);
