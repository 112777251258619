import axios from 'axios';
import config from "../../config";
import Notifications from "../utilities/Notifications";

class Api {

    constructor() {
        this.axios = axios.create({
            withCredentials: true,
            withXSRFToken: true,
            baseURL: config.apiEndpoint,
            timeout: 60000,
            headers: {
                'Accept': 'application/json',
                'X-Requested-With': 'XMLHttpRequest',
            },
        });
    }

    broadcastAuth(socketId, channelName) {
        return this.axios.post('broadcasting/auth', {
            socket_id: socketId,
            channel_name: channelName
        })
    }

    getCsrfCookie() {
        return this.withErrorHandling(async () => {
            return this.axios.get('csrf');
        });
    }

    login(credentials) {
        return this.withErrorHandling(async () => {
            return this.axios.post('auth/login', credentials);
        });
    }

    logout() {
        return this.withErrorHandling(async () => {
            return this.axios.post('auth/logout');
        });
    }

    acceptTos(turnstileToken) {
        return this.withErrorHandling(async () => {
            return this.axios.post('tos', {token: turnstileToken});
        });
    }

    getIdentity() {
        return this.withErrorHandling(async () => {
            return this.axios.post('identity');
        });
    }

    indexMedia(filters) {
        return this.withErrorHandling(async () => {
            return this.axios.get('media',{
                params: filters
            });
        });
    }

    storeMedia(media) {
        return this.withErrorHandling(async () => {
            return this.axios.post('media', media);
        });
    }

    getMedia(id) {
        return this.withErrorHandling(async () => {
            return this.axios.get(`media/${id}`);
        });
    }

    getTranscription(ulid, format) {
        return this.withErrorHandling(async () => {
            return this.axios.get(`transcription/${ulid}`, {
                params: { format },
            });
        });
    }

    updateTranscriptionResult(ulid, result) {
        return this.withErrorHandling(async () => {
            return this.axios.put(`transcription/${ulid}`, {result});
        });
    }

    destroyMedia(ulid) {
        return this.withErrorHandling(async () => {
            return this.axios.delete(`media/${ulid}`);
        });
    }

    getMediaUploadUri(ulid) {
        return this.withErrorHandling(async () => {
            return this.axios.post(`media/${ulid}/upload`);
        });
    }

    async uploadMedia(media, file, onProgress) {
        const uploadUri = await this.getMediaUploadUri(media.ulid);
        return axios({
            url: uploadUri,
            method: "put",
            data: file,
            headers: {"Content-Type": file.type},
            maxContentLength: (100 * 1024 * 1024 * 1024),
            timeout: (60 * 60 * 1000), // 60 mins
            onUploadProgress: (progressEvent) => {
                if (typeof onProgress === 'function') {
                    onProgress(progressEvent.loaded / progressEvent.total * 100)
                }
            }
        });
    }

    verifyMedia(ulid) {
        return this.withErrorHandling(async () => {
            return this.axios.post(`media/${ulid}/verify`);
        });
    }

    transcribeMedia(ulid) {
        return this.withErrorHandling(async () => {
            return this.axios.post(`media/${ulid}/transcribe`);
        });
    }

    productPrices(productName) {
        return this.withErrorHandling(async () => {
            return this.axios.get(`subscription/prices/${productName}`);
        });
    }

    subscriptionCheckout(name, interval) {
        return this.withErrorHandling(async () => {
            return this.axios.post('subscription/checkout', {name, interval});
        });
    }

    editSubscription() {
        return this.withErrorHandling(async () => {
            return this.axios.get('subscription/edit');
        });
    }

    updateWorkspace(ulid, data) {
        return this.withErrorHandling(async () => {
            return this.axios.put(`workspace/${ulid}`, data);
        });
    }

    vossyStatus() {
        return this.withErrorHandling(async () => {
            return this.axios.get(`/vossy/status`);
        });
    }

    // Private
    async withErrorHandling(callback) {
        try {
            const {data, status} = await callback();

            if (typeof data.redirect === 'string') {
                window.location = data.redirect;
            }

            if (status === 204) {
                return true;
            }

            if (data.result === undefined) {
                throw new Error('Server response format is incorrect.');
            }

            return data.result;
        } catch (error) {
            if (error.response) {
                const response = error.response;
                if (response.status === 422) {
                    const data = response.data;
                    error.isValidationError = true;
                    error.fields = data.errors;
                }

                if (response.data) {
                    if (response.data.message) {
                        Notifications.error(response.data.message);
                    }
                }
            }
            throw error;
        }
    }
}

export default new Api();
