import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import es from "./locales/es/translation.js";
import en from "./locales/en/translation.js";
import ja from "./locales/ja/translation.js";

const resources = {
    es,
    en,
    ja
};

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources,
        fallbackLng: "es",
        supportedLngs: ['es', 'en', 'ja'],
        detection: {
            order: ['localStorage', 'navigator'],
            caches: ['localStorage'],
            excludeCacheFor: ['cimode'],
            lookupLocalStorage: 'ilt-lng'
        },
        keySeparator: false,
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;

export const languages = [
    {
        code: 'es',
        name: 'Español',
    },
    {
        code: 'en',
        name: 'English',
    },
    {
        code: 'ja',
        name: '日本語',
    }
];
