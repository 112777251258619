import {Navigate} from "react-router-dom";
import Head from "../components/base/Head";
import {useSelector} from "react-redux";
import Auth from "../classes/api/Auth";
import config from "../config";
import { useTranslation } from "react-i18next";
import LanguageSwitcherMini from "../components/base/LanguageSwitcherMini";

export default function LoginPage() {
    const {user} = useSelector((state) => state.identity);
    const authUrl = Auth.redirectUrl();
    const { t } = useTranslation();

    if (user !== null) {
        return <Navigate to="/" replace={true}/>
    }

    return (
        <div className="min-h-100vh flex grow bg-slate-50 dark:bg-navy-900">
            <Head title={t('login.pageTitle')}/>

            <div className="hidden w-full place-items-center lg:grid">
                <div className="w-full max-w-lg p-6">
                    <img className="w-full" src="/images/illustrations/dashboard-check.svg" alt="image"/>
                </div>
            </div>

            <main className="flex w-full flex-col items-center bg-white dark:bg-navy-700 lg:max-w-md">
                <div className="flex w-full max-w-sm grow flex-col justify-center p-5">
                    <div className="text-center">
                        <div className="mx-auto" style={{maxWidth: 200}}>
                            <img style={{width: '100%'}} src="/images/logo-login.png" alt="logo"/>
                        </div>
                        <div className="mt-4">
                            <h2 className="text-2xl font-semibold text-slate-600 dark:text-navy-100">
                                {t('login.title')}
                            </h2>
                            <p className="text-slate-400 dark:text-navy-300">
                                {t('login.subtitle')}
                            </p>
                            <div className="flex items-center flex-col mt-4">
                                <a href={authUrl} className="border hover:bg-slate-200 rounded-full p-2 flex items-center">
                                    <img className="w-auto h-6" src="/images/icons/google.png" alt="logo"/>
                                    <span className="ml-2 font-bold text-md">
                                        {t('login.button')}
                                    </span>
                                </a>
                                <p className="text-xs mt-4 text-slate-500">
                                    {t('login.comment')}
                                </p>
                                <div>
                                    <LanguageSwitcherMini />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="my-5 flex justify-center text-xs text-slate-400 dark:text-navy-300">
                    <a target="_blank" href={config.tos}>{t('global.terms')}</a>
                </div>
            </main>
        </div>
    );
}
