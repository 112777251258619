import {Fragment} from "react";
import {LanguageIcon} from "@heroicons/react/24/solid";
import {Popover, Transition} from "@headlessui/react";
import {languages} from "../../lang/i18n";
import {useTranslation} from "react-i18next";

export default function LanguageSwitcher() {
    const {i18n} = useTranslation();

    const switchLanguage = (language, close) => {
        close();
        i18n.changeLanguage(language);
    }

    return (
        <div className="relative ml-2">
            <Popover className="relative">
                {({ close }) => (
                    <>
                        <Popover.Button className="flex justify-center items-center text-xs font-bold hover:underline">
                            <span className="uppercase">{i18n.language}</span>
                        </Popover.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-200"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-150"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel className="absolute mt-2 right-0">
                                <div className="drop-shadow w-32 mx-4 mt-1 flex flex-col rounded-lg border border-slate-150 bg-white shadow-soft dark:border-navy-800 dark:bg-navy-700 dark:shadow-soft-dark">
                                    <div className="p-2">
                                        <ul className="font-medium">
                                            {languages.map((language) =>
                                                <li key={language.code}>
                                                <button
                                                    onClick={() => switchLanguage(language.code, close)}
                                                    className="w-full inline-flex items-center space-x-2 transition-colors hover:text-slate-800 focus:text-slate-800 dark:hover:text-navy-100 dark:focus:text-navy-100"
                                                >
                                                    <LanguageIcon className="h-4 w-4" />
                                                    <span>{language.name}</span>
                                                </button>
                                            </li>
                                            )}
                                        </ul>
                                    </div>
                                </div>
                            </Popover.Panel>
                        </Transition>
                    </>
                )}
            </Popover>
        </div>
    );
}
