import {useTranslation} from "react-i18next";

export default function PageLoader() {
    const { t } = useTranslation();

    return (
        <div className="main-content w-full">
            <div
                className="flex w-full items-center flex-col bg-slate-50 dark:bg-navy-900 pt-20"
                style={{zIndex: 1000}}>
                <div
                    className="spinner h-10 w-10 animate-spin rounded-full border-[6px] border-info/30 border-r-info"/>
                <h3 className="text-xl font-light mt-2">{t("global.loaderText")}</h3>
            </div>
        </div>
    );
}
