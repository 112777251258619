import {InformationCircleIcon, ShoppingCartIcon} from "@heroicons/react/24/outline";
import {Link, useLocation} from "react-router-dom";
import {useSelector} from "react-redux";
import {useTranslation} from "react-i18next";

const hideOnPaths = [
    '/onboarding/plan',
    '/onboarding/subscription/success',
    '/auth/signout',
    '/auth/verify',
    '/workspace/edit'
]

export default function SubscriptionAlert() {
    const {workspace, properties} = useSelector((state) => state.identity);
    const {pathname} = useLocation();
    const {t} = useTranslation();

    if ((workspace.has_subscription || properties.is_demo) || hideOnPaths.includes(pathname)) {
        return null;
    }

    return (
        <div
            className="flex items-center justify-between pt-4 transition-all duration-[.25s]">
            <div className="flex items-center w-full">
                <div className="alert flex overflow-hidden rounded-lg border border-info text-info w-full">
                    <div className="bg-info p-3 text-white flex items-center">
                        <InformationCircleIcon className="h-8 w-8"/>
                    </div>
                    <div className="px-4 py-3 sm:px-5 flex-1">
                        <h4 className="text-lg">{t('alerts.subscription.title')}</h4>
                        <p>{t('alerts.subscription.comment')}</p>
                    </div>
                    <div className="flex items-center pr-4">
                        <Link to="/onboarding/plan"
                              className="btn bg-gradient-to-r from-sky-400 to-blue-600 font-medium text-white">
                            <ShoppingCartIcon className="h-6 w-6 mr-2"/> {t('global.subscribe')}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
}
