import {useDispatch, useSelector} from "react-redux";
import FullLoader from "../components/ui/FullLoader";
import {useEffect} from "react";
import Auth from "../classes/api/Auth";
import WebNotifications from "../classes/utilities/web-notifications";
import {setIdentity} from "../reducers/identity/identitySlice";

export default function AuthProvider({router, children}) {
    const {user, workspace} = useSelector((state) => state.identity);
    const dispatch = useDispatch();

    useEffect(() => {

        const onAuthChange = async ({identity}) => {
            dispatch(setIdentity(identity));
        }

        // Subscribe to identity changes.
        Auth.on('change', onAuthChange);

        Auth.autoLogin().catch(console.error);

        // unsubscribe from identity changes.
        return () => {
            Auth.off('change', onAuthChange);
        }
    }, []);


    useEffect(() => {
        if (!workspace.ulid) {
            return;
        }

        WebNotifications.subscribe(workspace.ulid);

        return () => {
            WebNotifications.unsubscribe();
        }
    }, [workspace.ulid]);

    const busy = user === undefined;
    const ready = !busy;

    return (
        <>
            <FullLoader active={busy}/>
            {ready && children}
        </>
    )
}
